import { LANGUAGES } from "../languages"

const en = {
  [LANGUAGES.ENGLISH]: {
    factory: 'Factory',
    wines: 'Wines',
    tours: 'Tours',
    events: 'Events',
    contacts: 'Contact Us',
    holani: 'Holani',
    anamor: 'Anamor',
    territory: 'Territory',
    vineyards: 'Vineyards',
    fermentation: 'Fermentation',
    cellars: 'Cellars',
    bottling: 'Bottling',
    hy: 'Arm',
    en: 'Eng',
    contact: 'Contact us',
    address1: '2213, C. Balahovit, Kotayk Marz',
    address2: 'Armenia, 10/1 Karmir Banakainneri str.',
    copyright: 'ManukyanBrandy@2023. All rights reserved.',
    learn: 'learn more',
    visit: 'visit us',
    taste: 'taste me',
    book: 'book a date',
    read: 'read more',
    subscribe: 'subscribe',
    subscribeText1: 'Want to be the first to know about our new events? Just enter your email address and hit the subscribe button!',
    // subscribeText2: 'Just enter your email address and hit the subscribe button!',
    see: 'become visitor',
    bookTour: 'book a tour',
    visitCellar: 'visit our cellars',
    allWines: 'All wines',
    clear: 'clear all',
    brand: 'Brand',
    year: 'Year',
    type: 'Type',
    openInGoogle: 'open in google maps',
    send: 'send',
    mandatory: '*this field is obligatory',
    nameError: 'name cannot exceed 30 characters',
    emailError: 'invalid email',
    phoneError: 'please enter a valid phone number, e.g. +1234567890',
    messageError: 'message cannot exceed 250 characters',
    thanks: 'Thank you! The message is sent',
    faqs: 'Frequently asked questions',
    question: 'Didn’t find your question?',
    writeUs: 'write us',
    onlyBook: 'Book',
    writeUsInSocials: 'Write us in socials:',
    leaveMessage: 'Or leave your message here:',
    past: 'Past',
    upcoming: 'Upcoming',
    upcomingEvents: 'Upcoming events',
    ageConfirm: 'Please confirm your age before entering.',
    ageConfirmText: 'By clicking “I am of legal age,” you confirm that you are of legal drinking age according to the laws of your country of residence.',
    legalAge: 'I’m legal age',
    notLegalAge: 'I’m under legal age',
    ageConfirmInfo: 'Excessive alcohol consumption can be harmful to your health.',
    oops: 'Oops!',
    oopsText: "We're Sorry. You must be of legal drinking age to enter this site. Please come back when you are old enough to enjoy our products responsibly. Thank you for understanding.",
    backTo: 'back to all events',
    back: 'back',
    errorOops: 'Ooops!',
    goHome: 'go to home',
    sthWentWrong: 'Something went wrong.',
    firstError: 'Please press the button below to go back to the homepage. If the error continues, please contact us.',
    ifRepeat: 'Thank you for your understanding.',
    step2: 'Step 2. Add personal data.',
    confirm: 'confirm',
    whatCanFind: 'What can you find here?',
    dateOfBirth: 'Date of birth',
    countryText: 'Country of residence',
    yourTime: 'Your time',
    language: 'Language:',
    numberOfVisitors: 'Number of visitors:',
    totalPrice: 'Total Price:',
    toursText: 'Discover the Manukyan Wine & Brandy Factory through our engaging wine tours. Visit our industrial units to see the winemaking process up close, from grape selection to bottling. Learn about our techniques and passion and enjoy tasting of our wines right where they are made.',
    seeWines: 'See our other wines',
    bookPlace: 'book your place',
    oopsLate: 'oops! you’re late',
    bookEvent: 'book',
    bookThisEvent: 'book this event',
    willHappen: 'will happen:',
    enterData: 'enter your data to register:',
    hooray: 'Hooray!',
    dear: 'Dear',
    weWating: 'We are waiting for you at',
    ok: 'ok',
    privacy: 'This is privacy',
    avalableTime: 'Available time:',
    other: 'other',
    next: 'next',
    step1: 'Step 1. Choose your date and time.',
    okay: 'okay',
    tour: 'tour',
    buy: 'buy in',
    agreeError: 'to book a tour, you must agree to the terms',
    visitTerroir: 'visit us',
    bookTour2: 'book',
    allInfo: 'all info',
    textUs: 'text us',
    privacyF: 'Privacy Policy',
    seePhotos: 'see photos',
    checkEvents: 'check upcoming events'
  }
}

export default en