import { LANGUAGES } from '../languages'

const am = {
  [LANGUAGES.ARMENIAN]: {
    factory: 'Գործարան',
    wines: 'Գինիներ',
    tours: 'Շրջայցեր',
    events: 'Իրադարձություններ',
    contacts: 'Կապ մեզ հետ',
    holani: 'Հոլանի',
    anamor: 'Անամոր',
    territory: 'Տեղանք',
    vineyards: 'Խաղողի այգիներ',
    fermentation: 'Ֆերմենտացիա',
    cellars: 'Մառաններ',
    bottling: 'Շշալցում',
    hy: 'Հայերեն',
    en: 'Անգլերեն',
    contact: 'Կապվեք մեզ հետ',
    address1: 'Կոտայքի մարզ, ք.Բալահովիտ 2213',
    address2: 'Հայաստան, Կարմիր Բանակաինների փող. 10/1',
    copyright: 'ManukyanBrandy@2023. Բոլոր իրավունքները պաշտպանված են',
    learn: 'իմանալ ավելին',
    visit: 'այցելեք մեզ',
    taste: 'համտեսիր ինձ',
    book: 'ամրագրել',
    read: 'ավելին',
    subscribe: 'բաժանորդագրվել',
    subscribeText1: 'Ցանկանու՞մ եք առաջինն իմանալ մեր նոր իրադարձությունների մասին: Պարզապես մուտքագրեք ձեր էլ. փոստի հասցեն և սեղմեք բաժանորդագրվել կոճակը',
    subscribeText2: '',
    see: 'բացահայտել ավելին',
    bookTour: 'ամրագրե՛ք շրջայց',
    visitCellar: 'այցելեք մեր մառաններ',
    allWines: 'Բոլոր գինիները',
    clear: 'մաքրել բոլորը',
    brand: 'Ապրանքանիշ',
    year: 'Տարի',
    type: 'Տեսակ',
    openInGoogle: 'բացել google maps-ում',
    send: 'ուղարկել',
    mandatory: '*այս դաշտը պարտադիր է',
    nameError: 'անունը չի կարող գերազանցել 30 նիշը',
    emailError: 'էլ․ փոստը սխալ է',
    phoneError: 'խնդրում ենք մուտքագրել վավեր հեռախոսահամար, օր․՝ +1234567890',
    messageError: 'հաղորդագրությունը չի կարող գերազանցել 250 նիշը',
    thanks: 'Շնորհակալություն! Հաղորդագրությունը ուղարկված է',
    faqs: 'Հաճախ տրվող հարցեր',
    question: 'Ունե՞ք այլ հարցեր',
    writeUs: 'գրեք մեզ',
    onlyBook: 'Պատվիրել',
    writeUsInSocials: 'Գրեք մեզ սոցիալական ցանցերում:',
    leaveMessage: 'Կամ թողեք ձեր հաղորդագրությունը այստեղ:',
    past: 'Անցյալ',
    upcoming: 'Գալիք',
    upcomingEvents: 'Գալիք իրադարձություններ',
    ageConfirm: 'Խնդրում ենք հաստատել ձեր տարիքը նախքան մուտք գործելը:',
    ageConfirmText: 'Սեղմելով «Ես օրինական տարիքի եմ»՝ դուք հաստատում եք, որ ձեր բնակության երկրի օրենքների համաձայն խմելու օրինական տարիքի եք:',
    legalAge: 'Ես օրինական տարիքի եմ',
    notLegalAge: 'Ես օրինական տարիքից ցածր եմ',
    ageConfirmInfo: 'Ալկոհոլի չափից ավելի օգտագործումը կարող է վնասակար լինել ձեր առողջության համար։',
    oops: 'Ափսոս!',
    oopsText: 'Ներեցե՛ք, այս կայքի բովանդակությունը տեսնելու համար Ձեր տարիքը պետք է համապատասխանի ալկոհոլային խմիչքների օգտագործման օրինական տարիքին: Շնորհակալություն ըմբռնման համար։',
    backTo: 'վերադառնալ բոլոր իրադարձություններին',
    back: 'հետ',
    errorOops: 'Ափսոս!',
    goHome: 'գնալ գլխավոր էջ',
    sthWentWrong: 'Նման էջ գոյություն չունի',
    firstError: 'Խնդրում ենք սեղմել ներքևի «գլխավոր» կոճակը վերադառնալու համար կայքի գլխավոր էջ:',
    ifRepeat: 'Եթե խափանումը շարունակվի, խմդրում ենք գրել մեզ:',
    step2: 'Քայլ 2. Ավելացրեք անձնական տվյալները։',
    confirm: 'հաստատել',
    agree: 'Ես համաձայն եմ մշակմանը',
    whatCanFind: 'ի՞նչ կտեսնեք այստեղ',
    dateOfBirth: 'Ծննդյան տարեթիվ',
    countryText: 'Բնակության երկիր',
    yourTime: 'Ձեր ժամը',
    language: 'Լեզու:',
    numberOfVisitors: 'Այցելուների քանակը։',
    totalPrice: 'Ընդհանուր գումարը։',
    toursText: 'Բացահայտեք «Մանուկյան» գինու և կոնյակի գործարանը մեր հետաքրքիր գինու շրջայցերի միջոցով: Այցելեք արտադրամասեր՝ մոտիկից տեսնելու գինու պատրաստման գործընթացը՝ սկսած խաղողի ընտրությունից մինչև շշալցում: Ծանոթացեք մեր գինեգործական մոտեցումերին, վայելեք մեր գինիները հենց այնտեղ, որտեղ դրանք պատրաստվում են:',
    seeWines: 'Տեսեք Մեր Մյուս Գինիները',
    bookPlace: 'պատվիրեք ձեր տեղը',
    oopsLate: 'ափսոս! դուք ուշացել եք',
    bookEvent: 'պատվիրել',
    bookThisEvent: 'պատվիրել այս իրադարձությունը',
    willHappen: 'կլինի:',
    enterData: 'մուտքագրեք ձեր տվյալները գրանցվելու համար:',
    hooray: 'ՈՒռա!',
    dear: 'Սիրելի',
    weWating: 'Սպասում ենք ձեզ',
    ok: 'լավ',
    privacy: 'Սա գաղտնիություն է', 
    avalableTime: 'Հասանելի ժամեր:',
    other: 'այլ',
    next: 'հաջորդը',
    step1: 'Քայլ 1. Ընտրեք ձեր ամսաթիվն ու ժամանակը:',
    okay: 'լավ',
    tour: 'տուր',
    buy: 'գնել',
    agreeError: 'տուրը ամրագրելու համար պետք է համաձայնեք պայմաններին',
    visitTerroir: 'այցելեք մեզ',
    bookTour2: 'պատվիրել',
    allInfo: 'ավելին',
    textUs: 'գրե՛ք մեզ',
    privacyF: 'Գաղտնիության Քաղաքականություն',
    seePhotos: 'տեսնել նկարները',
    checkEvents: 'ստուգել առաջիկա իրադարձությունները'
  }
}

export default am